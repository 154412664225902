<template>
  <div>
    <b-modal
      id="modal-reminder-task"
      @ok="handleOk"
      :title="taskOperationText"
      @hidden="closeModal"
      :cancel-title="'close'"
      v-model="openTaskModal"
      size="lg"
      v-b-modal.modal-center
      style="height: 100vh;"
    >
      <b-overlay :show="loading" rounded="sm" no-fade>
        <validation-observer ref="reminderTaskRules">
          <b-row>
            <b-col cols="12" md="8" xl="8">
              <b-form-group label="Title" label-for="title">
                <validation-provider
                  name="Title"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    placeholder="Title"
                    v-model="taskForm.title"
                    :disabled="taskOperation === 'View'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4" xl="4">
              <b-form-group label="Date" label-for="datepicker">
                <validation-provider
                  name="Date"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-datepicker
                    id="datepicker"
                    :min="minDate"
                    locale="en"
                    v-model="taskForm.date"
                    :disabled="taskOperation === 'View'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="8" xl="8">
              <b-form-group label="Description" label-for="desc">
                <validation-provider
                  name="Description"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    id="desc"
                    placeholder="Description"
                    v-model="taskForm.description"
                    :disabled="taskOperation === 'View'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-overlay>

      <template #modal-footer>
        <b-row>
          <b-col
            cols="6"
            class="d-flex align-items-center justify-content-between"
          >
            <b-form-checkbox
              checked="true"
              class="custom-control-primary"
              name="check-button"
              switch
              v-model="taskForm.repeat"
              v-b-tooltip.hover.top="'Repeat Every Year'"
              :disabled="taskOperation === 'View'"
            >
              <span class="switch-icon-left">
                <feather-icon icon="BellIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="BellOffIcon" />
              </span>
            </b-form-checkbox>
            <b-button
              variant="primary"
              size="md"
              class="ml-1"
              @click="handleOk"
            >
              <span style="white-space: nowrap;">{{ taskOperationText }}</span>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  BFormTimepicker,
  VBTooltip,
  BTooltip,
  BCalendar,
  BFormCheckbox,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import {
  required,
  alphaNum,
  email,
  max,
  max_value,
  min_value,
} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ReminderTasksModule from "./ReminderTasksModule";

export default {
  name: "taskformmodal",
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BFormCheckbox,
    VBModal,
    BIcon,
    BPopover,
    BFormTimepicker,
    BTooltip,
    BCalendar,
    vSelect,
    ToastificationContent,
    flatPickr,
    Cleave,

    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const TASK_APP_STORE_MODULE_NAME = "reminderTasks";
    // Register module
    if (!store.hasModule(TASK_APP_STORE_MODULE_NAME))
      store.registerModule(TASK_APP_STORE_MODULE_NAME, ReminderTasksModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TASK_APP_STORE_MODULE_NAME))
        store.unregisterModule(TASK_APP_STORE_MODULE_NAME);
    });
  },
  mounted() {},
  computed: {
    taskOperationText() {
      if (this.taskOperation === "Add") {
        return "Add New Task";
      } else if (this.taskOperation === "Update") {
        return "Update Task";
      } else if (this.taskOperation === "View") {
        return "Close";
      }
    },
  },
  data() {
    const now = new Date();

    return {
      taskOperation: "Add",
      openTaskModal: false,
      loading: false,
      minDate: now,
      taskForm: {
        id: 0,
        title: null,
        description: null,
        date: null,
        repeat: false,
      },
    };
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.reminderTaskRules.validate().then((success) => {
        if (success) {
          this.loading = true;
          if (this.taskOperation === "Add") {
            store
              .dispatch("reminderTasks/createNewTask", { task: this.taskForm })
              .then((res) => {
                this.$emit("fetch-tasks");
                this.closeModal();
                this.successMessage("Task Created Successfully");
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.loading = false;
              });
          } else if (this.taskOperation === "Update") {
            store
              .dispatch("reminderTasks/updateTask", this.taskForm)
              .then((res) => {
                this.$emit("fetch-tasks");
                this.closeModal();
                this.successMessage("Task Updated Successfully");
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.closeModal();
          }
        }
      });
    },
    closeModal() {
      this.openTaskModal = false;
      this.loading = false;
      this.taskOperation = "Add";
      this.taskForm = {
        id: 0,
        title: null,
        description: null,
        date: null,
        repeat: false,
      };
    },
    successMessage(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successful",
          text: "✔️" + text,
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    errorMessage(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: "❌" + text,
          icon: "AlertOctagonIcon ",
          variant: "danger",
        },
      });
    },
  },
};
</script>

<style></style>
