<template>
  <div class=" mt-2">
    <!-- Table Container Card -->

    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <!-- Table Top -->

          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="7"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>tasks </label>
            </b-col>

            <b-col cols="12" md="5" v-if="$Can('task_add_btn')">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="filter"
                  class="d-inline-block mr-1"
                  cle
                  placeholder="Search in Table"
                />
                <b-button variant="primary" @click="addTask">
                  <span class="text-nowrap">Add Task</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="reftasksListTable"
          class="position-relative"
          :items="fetchReminderTasks"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          responsive
          :small="true"
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
          :current-page="currentPage"
          :per-page="perPage"
          @row-selected="viewTask"
          style="min-height: 20vh;"
        >
          <!-- Column: Id -->
          <template #cell(id)="data">
            <strong class="text-primary">#{{ data.value }}</strong>
          </template>

          <!-- Column: title -->
          <template #cell(title)="data">
            {{ data.value }}
          </template>

          <template #cell(user)="data">
            {{ data.item.user.name }}  {{ data.item.user.surname }}
          </template>


          <!-- Column: desc -->
          <template #cell(description)="data">
            {{ data.value }}
          </template>

          <!-- Column: date -->
          <template #cell(date)="data">
            {{ data.value }}
          </template>

          <!-- Column: repeat -->
          <template #cell(repeat)="data">
            <b-badge
              v-if="data.value"
              pill
              :variant="`light-primary`"
              class="text-capitalize"
              v-b-tooltip.hover.top="'Task repeats every year'"
            >
              ✔️
            </b-badge>

            <b-badge
              v-else
              pill
              :variant="`light-primary`"
              class="text-capitalize"
            >
              ❌
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell()="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                @click="editTask(data.item)"
                v-if="$Can('task_edit_btn')"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit Task</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="deleteTask(data.item)"
                v-if="$Can('task_delete')"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Remove Task</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>

    <TaskFormModal ref="refTaskFormModal" @fetch-tasks="getTasks" />
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
} from "bootstrap-vue";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import { onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import ReminderTasksModule from "./ReminderTasksModule";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import TaskFormModal from "./TaskFormModal.vue";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    TaskFormModal,
    ToastificationContent,
  },

  setup() {
    const TASKS_APP_STORE_MODULE_NAME = "reminderTasks";
    // Register module
    if (!store.hasModule(TASKS_APP_STORE_MODULE_NAME))
      store.registerModule(TASKS_APP_STORE_MODULE_NAME, ReminderTasksModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TASKS_APP_STORE_MODULE_NAME))
        store.unregisterModule(TASKS_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      fetchReminderTasks: [],

      filterOn: ["title", "description"],
      tableColumns: [
        { key: "id", sortable: false, class: "text-center" },
        { key: "user", sortable: true, class: "text-center" },
        { key: "title", sortable: true, class: "text-center" },
        { key: "description", sortable: false, class: "text-center" },
        { key: "date", sortable: true, class: "text-center" },
        { key: "repeat", sortable: false, class: "text-center" },
        { key: "actions", label: "#", class: "text-center" },
      ],
      perPage: localStorage.getItem("reminderTaskShow") ?? 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      filter: null,
      loading: false,
    };
  },

  created() {
    this.getTasks();
  },

  computed: {
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  methods: {
    getTasks() {
      this.loading = true;
      store
        .dispatch("reminderTasks/getReminderTasks")
        .then((res) => {
          this.fetchReminderTasks = res.data;
          this.totalRows = res.data.length;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addTask() {
      this.$refs.refTaskFormModal.openTaskModal = true;
    },
    viewTask(val) {
      const taskForm = val[0] ?? null;
      this.$refs.refTaskFormModal.openTaskModal = true;
      this.$refs.refTaskFormModal.taskOperation = "View";
      this.$refs.refTaskFormModal.taskForm = taskForm;
      this.$refs.refTaskFormModal.taskForm.date = moment(
        taskForm.date,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");

      //   this.$refs.refTaskFormModal.taskForm = task[0];
    },
    editTask(task) {
      this.$refs.refTaskFormModal.openTaskModal = true;
      this.$refs.refTaskFormModal.taskOperation = "Update";
      this.$refs.refTaskFormModal.taskForm = Object.assign({}, task);
      this.$refs.refTaskFormModal.taskForm.date = moment(
        task.date,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
    },
    deleteTask(task) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to remove this task?", {
          size: "sm",
          title: "Task Remove Confirmation",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            store
              .dispatch("reminderTasks/removeTask", { id: task.id })
              .then((res) => {
                this.getTasks();
                this.successMessage("Task removed Successfully");
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    successMessage(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successful",
          text: "✔️" + text,
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    errorMessage(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: "❌" + text,
          icon: "AlertOctagonIcon ",
          variant: "danger",
        },
      });
    },
  },
  watch: {
    // perPage: {
    //   handler: function(val, before) {
    //     localStorage.setItem("userShow", val);
    //   },
    // },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
