import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createNewTask(ctx, task) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("createNewTask", task)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getReminderTasks(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("getReminderTasks")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateTask(ctx, task) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put("updateTask", task)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    removeTask(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("removeTask", id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
